/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import DragFileFormik from '../../components/formik/dragFile';

import InputFormik from '../../components/formik/input';
import PublishAdvertisement from './modals/publishAdvertisement';
import { useAppDispatch } from '../../redux/hooks';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import { editMarketingInfo, createMarketingInfo } from '../../redux/thunks/marketingThunk';

interface MarketingInfo {
  id?: string;
  title: string;
  description: string;
  advantages: string;
  cta: string;
  link: string;
  image: any;
  status: number | boolean;
}

interface MarketingMobileProps {
  img: string;
  onMarketingSuccessfulSave: (data: MarketingInfo) => void;
  marketing: MarketingInfo;
  formType: string;
  setImg: React.Dispatch<React.SetStateAction<any>>;
  id: string | undefined;
}

function MarketingForm({
  onMarketingSuccessfulSave,
  marketing,
  img,
  formType,
  setImg,
  id,
}: MarketingMobileProps) {
  const dispatch = useAppDispatch();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [statusTypeClicked, setStatusTypeClicked] = useState<string>('publish');
  // const [formToSubmit, setFormToSubmit] = useState<any>({});

  return (
    <Formik
      initialValues={{
        ...marketing,
      }}
      enableReinitialize
      validationSchema={Yup.lazy((values) => {
        const { title, description, link, image, advantages, cta } = values;
        if (
          (title || description || link || cta || image || advantages) &&
          (title !== '' ||
            description !== '' ||
            link !== '' ||
            image !== '' ||
            advantages !== '' ||
            cta !== '')
        ) {
          return Yup.object().shape({
            title: Yup.string().required('Please enter the title.'),
            description: Yup.string().required('Please enter the description.'),

            advantages: Yup.string().required('Please enter the advantages.'),
            cta: Yup.string(),
            // image: Yup.string().required('Please Upload the image'),
          });
        }
        return Yup.mixed().notRequired();
      })}
      onSubmit={() => {}}
    >
      {({ submitForm, validateForm, setValues, values, errors }) => {
        useEffect(() => {
          setValues(marketing);
        }, [marketing]);

        const handleModalClick = () => {
          const marketingToSave = {
            ...values,
            type: formType,
            status: statusTypeClicked === 'publish' ? 1 : 0,
          };
          const thunkSave = values?.id ? editMarketingInfo : createMarketingInfo;
          dispatch(thunkSave(marketingToSave)).then(({ payload }) => {
            onMarketingSuccessfulSave({ ...marketingToSave, id: payload.id });
          });
        };

        const submitClick = (statusType: string = 'publish') => {
          submitForm();
          validateForm().then((err) => {
            if (Object.keys(err).length === 0) {
              setStatusTypeClicked(statusType);
              dispatch(modalActions.setVisibleModal(`${formType}PublishAdvertisementModal`));
            }
          });
        };
        return (
          <>
            <div className="row mb-4" data-formtype={formType}>
              <div className="col-12 p-0">
                <div className="radius-16 p-3 p-xl-4">
                  <form>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                        <Field
                          component={InputFormik}
                          type="text"
                          name="title"
                          description="title"
                          placeholder="Title of Advertisement"
                        />
                        <Field
                          component={InputFormik}
                          type="text"
                          name="description"
                          description="description"
                          placeholder="Description of Advertisement"
                        />
                        <Field
                          component={InputFormik}
                          type="text"
                          name="advantages"
                          description="advantages"
                          placeholder="Text of Advantages"
                        />
                        <Field
                          component={InputFormik}
                          type="text"
                          name="cta"
                          description="Call to Action"
                          placeholder="Text of CTA"
                        />
                        <Field
                          component={InputFormik}
                          type="text"
                          name="link"
                          description="Link"
                          placeholder="Link"
                        />
                      </div>
                      <div className="col-xl-1 d-none d-xxl-block" />
                      <div className="col-12 col-lg-6 col-xxl-5 d-flex">
                        <div className="col-2 col-xxl-0" />

                        <div className="col-7 flex-grow-1 bg-color-white radius-16 p-4">
                          <Field
                            component={DragFileFormik}
                            name="image"
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            img={img}
                            setImg={setImg}
                            valueType="file"
                            id={id}
                          />
                        </div>

                        <div className="col-2" />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <button
                          className="btn btn-warning px-5 mt-3 me-3"
                          type="button"
                          onClick={() => submitClick()}
                        >
                          <i className="fa-solid fa-cloud-arrow-up" />
                          Publish
                        </button>
                        {marketing && (marketing?.status === true || marketing?.status === 1) && (
                          <button
                            className="btn btn-danger px-5 mt-3"
                            type="button"
                            onClick={() => submitClick('unpublish')}
                          >
                            <i className="fa-solid fa-cloud-arrow-down" />
                            Unpublish
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <PublishAdvertisement
              handlePublish={handleModalClick}
              prefixModalName={formType}
              unpublishMode={statusTypeClicked !== 'publish'}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default MarketingForm;
